import React, { CSSProperties, FC, ReactElement, useEffect, useState } from 'react'
import cn from 'classnames'
import TextareaAutosize from 'react-autosize-textarea'

type FIProps = {
	type: string
	placeholder?: string
	placeholderStatic?: string
	value?: string
	description?: string
	error?: string
	onChange: (val: any) => void
	iconLeft?: () => ReactElement
	required?: boolean
	className?: string
	staticInput?: boolean
	requiredCount?: number
	styleContainer?: CSSProperties | undefined
}

const FormInput: FC<FIProps> = ({
	type,
	className = '',
	staticInput = false,
	placeholder,
	placeholderStatic,
	value = '',
	error,
	onChange,
	description,
	requiredCount = undefined,
	iconLeft,
	styleContainer,
	...props
}) => {
	const [touched, setTouched] = useState<boolean>(
		(!!placeholder && !!placeholderStatic) || value.length > 0
	)
	const [val, setVal] = useState<string>(value)

	const [view, setView] = useState<boolean>(false)

	const onChangeVal = e => {
		setVal(e.target.value)
		onChange(e)
		// console.log(e.target.value)
	}

	useEffect(() => {
		setTouched((!!placeholder && !!placeholderStatic) || value.length > 0)
	}, [val])

	useEffect(() => {
		if (value !== val) {
			setVal(value)
		}
	}, [value])

	const valueLength = val?.toString()?.replace(/ /gi, '').length

	return (
		<div
			className={cn('form-input form-input--rich', {
				touched,
				'form-input--icon-left': !!iconLeft,
				static: staticInput,
				'form-input--icon-right': type === 'password-toggled',
				'is-invalid': !!error,
			})}
			style={styleContainer}
		>
			{iconLeft && typeof iconLeft === 'function' ? (
				<div className="icon-left">{iconLeft()}</div>
			) : undefined}
			<div className="input-placeholder">{placeholder}</div>

			{type === 'textarea' ? (
				<TextareaAutosize
					value={val}
					onChange={onChangeVal}
					placeholder={placeholderStatic || ''}
					className={cn(
						'form-control',
						{
							'is-invalid': !!error,
						},
						className
					)}
					{...props}
				/>
			) : (
				<input
					type={type === 'password-toggled' ? (view ? 'text' : 'password') : type}
					value={val}
					placeholder={placeholderStatic || ''}
					// onInput={e => {
					// 	console.log('input', e)
					// }}
					// onChange={e => {
					// 	console.log('change', e.target.value)
					// }}
					onChange={onChangeVal}
					className={cn('form-control', {
						'is-invalid': !!error,
					})}
					{...props}
				/>
			)}

			{type === 'password-toggled' ? (
				<div className="icon-right" onClick={() => setView(!view)}>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M6.07315 9C6.07315 10.6 7.3829 11.9018 8.99998 11.9018C10.6097 11.9018 11.9195 10.6 11.9195 9C11.9195 7.39273 10.6097 6.09091 8.99998 6.09091C7.3829 6.09091 6.07315 7.39273 6.07315 9ZM13.3024 4.53455C14.5829 5.52364 15.6732 6.97091 16.4561 8.78182C16.5146 8.92 16.5146 9.08 16.4561 9.21091C14.8902 12.8327 12.1024 15 9 15H8.99268C5.89756 15 3.10976 12.8327 1.5439 9.21091C1.48537 9.08 1.48537 8.92 1.5439 8.78182C3.10976 5.16 5.89756 3 8.99268 3H9C10.5512 3 12.022 3.53818 13.3024 4.53455ZM9.00085 10.8093C10.0033 10.8093 10.8228 9.99477 10.8228 8.9984C10.8228 7.99477 10.0033 7.18022 9.00085 7.18022C8.91305 7.18022 8.82524 7.18749 8.74475 7.20204C8.71548 8.00204 8.05695 8.64204 7.24475 8.64204H7.20817C7.18622 8.7584 7.17158 8.87477 7.17158 8.9984C7.17158 9.99477 7.99109 10.8093 9.00085 10.8093Z"
							fill="#0A1935"
						/>
					</svg>
				</div>
			) : undefined}

			{requiredCount && !(valueLength > 0 && valueLength >= requiredCount) ? (
				<span
					className={cn('typography typography--xsmall color color--faded description', {
						'color--error': valueLength > 0 && valueLength < requiredCount,
						'color--success': valueLength > 0 && valueLength >= requiredCount,
					})}
				>
					{valueLength}/{requiredCount}
				</span>
			) : (
				''
			)}
			{description ? (
				<span className="typography typography--xsmall color color--faded description">
					{description}
				</span>
			) : (
				''
			)}
			{error ? (
				<span className="typography typography--small typography--error error">{error}</span>
			) : (
				''
			)}
		</div>
	)
}

export { FormInput }
